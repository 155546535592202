<template>
  <b-modal
    id="lots-by-selection-modal"
    hide-header
    hide-footer
    centered
    size="xl"
  >
    <div class="header">
      <div class="header-content">
        <p>Conferir guias selecionadas - Lote Nº{{invoice.number}}</p>
        <Close class="close-svg" @click="onClose" />
      </div>
    </div>

    <b-container class="py-3 px-4">
      <b-row>
        <b-col cols="9">
          <b-form-group>
            <label for="patient">Paciente</label>
            <multiselect
              id="patient"
              v-model="filter.patient"
              :options="patientOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecionar"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Nenhum paciente encontrado
              </template>
              <template slot="noResult">Nenhum resultado encontrado</template>
            </multiselect>
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group>
            <label for="guideNumber">Nº da Guia</label>
            <multiselect
              id="guideNumber"
              v-model="filter.guideNumber"
              :options="guideNumbersOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              placeholder="Selecionar"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                Nenhum plano de saúde encontrado
              </template>
              <template slot="noResult">Nenhum resultado encontrado</template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-card v-if="filteredGuides?.length">
            <b-table-simple striped id="guides-table">
              <thead>
                <tr class="tr-header">
                  <th></th>
                  <th>Data</th>
                  <th>Paciente</th>
                  <th>Nº da Guia</th>
                  <th>Profissional exec.</th>
                  <th>Prazo envio</th>
                  <th>Tipo de guia</th>
                  <th>Valor</th>
                  <th>Conferido</th>
                  <th></th>
                </tr>
              </thead>

              <tbody 
                v-for="(guide, index) in filteredGuides" 
                :key="index"
              >
                <tr>
                  <td>
                    <Trash 
                      class="icon trash pb-1" 
                      @click="removeSelectedGuide(index)" 
                      v-b-tooltip.hover title="Remover guia da geração de lote"
                    />
                  </td>
                  <td>{{ guide['Data criação'] }}</td>
                  <td>{{ guide['Paciente'] }}</td>
                  <td>{{ guide['N° da guia'] }}</td>
                  <td>{{ guide['Profissional exec.'][0] ?? '-'}}</td>
                  <td>{{ guide['Prazo envio'] }}</td>
                  <td>{{ guide['Tipo de guia'] }}</td>
                  <td>{{ sumGuideValue(guide.Detalhes) }}</td>
                  <td>
                    <label class="checkbox ml-2 pb-3">
                      <input 
                        type="checkbox" 
                        v-model="guide.Conferido" 
                        @change="changeCheck(row)" 
                      />
                      <span class="checkmark">
                        <Check />
                      </span>
                    </label>
                  </td>

                  <td>
                    <div class="actions">
                      <b-button v-b-toggle="`collapse-${guide.id}-${index + 100}`" class="toggle-btn" @click="rotateIcon"
                        :disabled="!guide?.Detalhes?.length">
                        <ChevronDown class="icon" />
                      </b-button>
                      <div class="options">
                        <MoreVertical class="more-icon" />
                        <div class="menu">
                          <b-button variant="link" 
                            v-if="guide.files_count"
                            @click="openDocumentModal(guide)">
                            Abrir arquivos
                          </b-button>
                          <b-button 
                            variant="link"
                            @click="getGuide(guide)">
                            Ver detalhes
                          </b-button>
                          <b-button variant="link" @click="openHistoric(guide.id)">
                            Histórico de ações
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr class="collapsable-row">
                  <td colspan="9">
                    <b-collapse :id="`collapse-${guide.id}-${index + 100}`" class="collapse">
                      <b-table-simple class="collapse-table">
                        <thead>
                          <tr>
                            <th scope="col">Atualização</th>
                            <th scope="col">Tabela</th>
                            <th scope="col">Cód. Item</th>
                            <th scope="col">Descrição</th>
                            <th scope="col">Valor faturado</th>
                            <th scope="col">Valor pago</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(detalhe, index) in guide.Detalhes" :key="index">
                            <td>{{ detalhe['Atualização'] }}</td>
                            <td>{{ detalhe['Tabela'] }}</td>
                            <td>{{ detalhe['Cód. Item'] }}</td>
                            <td>{{ detalhe['Descrição'] }}</td>
                            <td>{{ detalhe['Valor faturado'] }}</td>
                            <td>{{ detalhe['Valor pago'] }}</td>
                          </tr>
                        </tbody>
                      </b-table-simple>
                    </b-collapse>
                  </td>
                </tr>
              </tbody>
            </b-table-simple>
          </b-card>

          <b-card v-else>
            <NoFilterResults message="Os filtros aplicados não geraram nenhum resultado." />
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-row class="d-flex justify-content-between px-4 mb-2">
      <div>
        <span class="footer-text">
          {{selectedGuides.length ? selectedGuides.length: ''}} guia{{selectedGuides.length > 1 ? 's' : ''}} presente{{selectedGuides.length > 1 ? 's' : ''}} neste lote
        </span>
      </div>
      <div>
        <b-button
          class="mr-2"
          variant="outline-primary"
          @click="onClose"
        >
          Voltar
        </b-button>
        <b-button
          variant="primary"
          :disabled="!userHasPermission('FatTissFat3')"
          @click="storeBatch"
        >
          Gerar Lote
        </b-button>
      </div>
    </b-row>
  </b-modal>
</template>

<script>
import { userHasPermission } from '@/utils/localStorageManager'
import { parseNumberToMoney, parseMoneyToNumber } from '@/utils/moneyHelper'

export default {
  name: 'LotsBySelectionModal',
  components: {
    Check: () => import('@/assets/icons/check.svg'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Trash: () => import( '@/assets/icons/trash.svg'),
    NoFilterResults: () => import('@/components/General/noFilterResults')
  },
  props: {
    invoice: Object,
    selectedType: String,
    selectedGuides: Array,
    openDocumentModal: Function,
    openHistoric: Function,
    getGuide: Function
  },
  data() {
    return {
      currentBatchNumber: 0,
      maxGuidesBatch: null,
      filter: {
        patient: 'Selecionar',
        guideNumber: 'Selecionar'
      },
      patientOptions: [],
      guideNumbersOptions: []
    }
  },
  computed: {
    filteredGuides() {
      let filteredRes = this.selectedGuides
      if(this.filter.patient !== 'Selecionar'){
        filteredRes = filteredRes.filter(guide => guide.Paciente === this.filter.patient)
      }

      if(this.filter.guideNumber !== 'Selecionar'){
        filteredRes = filteredRes.filter(guide => guide['N° da guia'] === this.filter.guideNumber)
      }

      return filteredRes
    }
  },
  methods: {
    userHasPermission,
    parseNumberToMoney,
    parseMoneyToNumber,
    onClose() {
      this.$bvModal.hide('lots-by-selection-modal')
    },
    rotateIcon($event) {
      const icon = $event.target
      icon.classList.toggle('rotate')
    },
    sumGuideValue(guideDetails){
      let sumValue = 0
      guideDetails.forEach(guide => {
        sumValue += parseMoneyToNumber(guide['Valor faturado'])
      });
      return parseNumberToMoney(sumValue)
    },
    removeSelectedGuide(index){
      this.selectedGuides.splice(index, 1)
      if(!this.selectedGuides.length) this.onClose()
    },
    async storeBatch() {
      const isLoading = this.$loading.show()
      try {
        await this.api.storeTissBatchByGuideIds({
          tiss_invoice_id: this.invoice.id,
          clinic_health_plan_id: this.invoice.clinic_health_plan_id,
          selected_guides: this.selectedGuides.map((el) => {return el.id})
        })
        this.$toast.success('Lote gerado com sucesso')
        this.onClose()
        this.$emit('change-tab', { tab: 'lotes-a-enviar', index: 2 })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getCurrentBatchNumber() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getCurrentBatchNumber(
          this.invoice.id,
          this.invoice.clinic_health_plan_id
        )
        this.currentBatchNumber = response.data.currentBatchNumber
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  },
  watch: {
    selectedGuides(newVal) {
      this.patientOptions = ['Selecionar']
      this.guideNumbersOptions = ['Selecionar']

      newVal.map(guide => {
        if(this.patientOptions.findIndex(patient => patient === guide.Paciente) === -1) this.patientOptions.push(guide.Paciente)
        if(this.guideNumbersOptions.findIndex(guideNumber => guideNumber === guide['N° da guia']) === -1) this.guideNumbersOptions.push(guide['N° da guia'])
      })
    },
    invoice: {
      async handler(value) {
        if (Object.keys(value).length) {
          this.$toast.info('Confira as guias selecionadas e gere o lote')
          if(value.id && value.clinic_health_plan_id) await this.getCurrentBatchNumber()
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
#lots-by-selection-modal {
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);

    .modal-body {
      padding: 0;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: var(--type-active);

      .header {
        border-bottom: 1px solid var(--neutral-200);

        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 24px;

          .close-svg {
            height: 24px;
            width: 24px;
            fill: var(--neutral-500);
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .body-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 50%;

          p {
            margin: 40px 0;
          }

          .batch-number {
            font-weight: bold;
            color: var(--blue-500);
          }

          .warning-svg {
            height: 96px;
            width: 96px;
            rect:first-child {
              fill: var(--blue-100);
            }

            rect:nth-child(2) {
              fill: var(--blue-500);
            }
          }

          .buttons {
            display: flex;
            flex-direction: row;

            .button {
              width: 168px;
              height: 40px;
              &:last-child {
                margin-left: 2%;
              }
            }

            .outline {
              margin: 0 5px;
              color: var(--neutral-000);
            }
          }
        }
      }
    }
  }

  .tiss-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
  }

  .footer-text{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 28px;
    color: var(--type-active);
  }

  #guides-table {
    width: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
    table-layout: fixed;
    margin: 5px 0;
  
    thead {
      font-weight: 600;
      color: var(--dark-blue);
  
      tr {
        background-color: var(--neutral-100);
      }
    }
  
    tbody {
      color: var(--type-active);
      font-weight: 600;
  
    }
  
    .spacing {
      height: 120px;
    }
  
    td {
      max-width: 350px !important;
    }
  
    td,
    th {
      @media (max-width: 1330px) {
        font-size: 12px;
      }
  
      .situation-tag {
        width: 100%;
        border-radius: 16px;
        padding: 5px 10px;
        background-color: var(--neutral-300);
        display: inline-block;
        text-align: center;
      }
  
      .text-tag {
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        white-space: nowrap !important;
      }
  
      .toggle-btn {
        background: var(--blue-100);
        border: none;
        padding: 5px !important;
  
        .icon {
          width: 25px;
          transition: all 500ms;
        }
      }
  
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
  
      .multi-content {
        display: flex;
        align-items: center;
        
        .multi-content-container {
          display: flex;
          flex-direction: row;
          align-items: center;
  
          .text-container {
            max-width: 10ch !important;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
  
        .popover-element-container {
          visibility: hidden !important;
          position: relative;
          padding: 10px;
          border-radius: 8px;
          border: 1px solid var(--neutral-300);
          background-color: var(--neutral-000);
          // margin-top: 20px;
        }
      }
  
      .multi-content:hover {
        .popover-element-container {
          visibility: visible !important;
          p {
            max-width: 20ch !important;
          }
        }
      }
  
      .checkbox {
        margin-left: 25px !important;
      }
  
      .actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
  
      .options {
        cursor: pointer;
        width: 24px;
        height: 28px;
        position: relative;
        display: inline-block;
  
        .more-icon {
          width: 24px;
          height: 24px;
          stroke: var(--neutral-500);
          transition: all 0.5s;
          cursor: pointer;
        }
  
        .menu {
          z-index: 10;
          min-width: 180px;
          position: absolute;
          top: 28px;
          right: 0;
          background: var(--neutral-000);
          border: 1px solid var(--neutral-200);
          box-sizing: border-box;
          box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
            0px 4px 10px -8px rgba(12, 29, 89, 0.2);
          border-radius: 8px;
          padding: 0;
          opacity: 0;
          transition: all 0.5s;
          flex-direction: column;
          align-items: start;
          display: none;
  
          .mtb-12 {
            margin: 12px 0;
          }
  
          .btn {
            font-weight: 600;
            width: 100%;
  
            &.remove {
              color: var(--states-error);
            }
  
            &.btn-link {
              text-align: left;
              padding: 12px 18px !important;
            }
          }
        }
  
        &:hover {
          .more-icon {
            transform: rotate(90deg);
          }
  
          .menu {
            opacity: 1;
            display: flex;
            z-index: 10;
          }
        }
      }
    }
  
    .hide-btn {
      color: var(--blue-700);
      text-decoration: underline;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  
    td:last-child {
      overflow: visible;
    }
  
    .collapsable-row {
      td {
        padding: 0;
        border-top: none;
        border-bottom: 0.5px dotted var(--neutral-300);
      }
  
      .collapse-table {
        width: 100%;
        margin: 0 auto 20px auto;
  
        thead {
          tr {
            border-radius: 8px !important;
            background-color: var(--neutral-200);
  
            th {
              color: var(--dark-blue);
              font-weight: 600;
              font-size: 16px;
            }
  
            th:nth-child(1) {
              border-top-left-radius: 8px;
            }
  
            th:last-child {
              border-top-right-radius: 8px !important;
            }
          }
        }
  
        tbody {
          border: 1px dashed var(--neutral-200);
  
          tr {
            td {
              padding: 15px 5px;
            }
  
            td:nth-child(4) {
              max-width: 35ch;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  
    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
  
      &.trash {
        fill: none;
        stroke: var(--states-red-soft);
      }
  
      &.trash:hover {
        stroke: var(--states-error);
      }
    }
  }
}
</style>
